<template>
  <div>
    <h1 class="mt-5 mb-4 text-center">Leaderboard <br/><small>Penangkap DPO Terbanyak</small></h1>
    <b-row class="justify-content-center mb-3">
      <b-col md="4" class="text-center" v-for="(juara, index) in [juara2, juara1, juara3]" :key="index">
        <img :src="juara.gambar" width="80px" :alt="'Juara ' + (index + 1)" class="img-fluid mb-2">
        <h3>{{ juara.title }}</h3>
        <h4>{{ juara.nama }}</h4>
        <p class="mb-2">{{ juara.skor }} DPO Tertangkap</p>
      </b-col>
    </b-row>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      juara1: { title: 'Juara 1', nama: 'Serpa Alexander Lukashenko', skor: 5, gambar: 'https://png.pngtree.com/element_our/20200702/ourmid/pngtree-medal-champion-vector-element-image_2283676.jpg' },
      juara2: { title: 'Juara 2', nama: 'Kolonel Johnson Alvarez', skor: 3, gambar: 'https://png.pngtree.com/element_our/20200702/ourmid/pngtree-medal-champion-vector-element-image_2283676.jpg' },
      juara3: { title: 'Juara 3', nama: 'Serpa Joki Kendil', skor: 2, gambar: 'https://png.pngtree.com/element_our/20200702/ourmid/pngtree-medal-champion-vector-element-image_2283676.jpg' },
      leaderboard: [
        { nama: 'Pemain 1', skor: 70, kategori: 'Kategori 1' },
        { nama: 'Pemain 2', skor: 60, kategori: 'Kategori 2' },
        { nama: 'Pemain 3', skor: 50, kategori: 'Kategori 1' },
        // Tambahkan data pemain lain jika diperlukan
      ],
      fields: [
        { key: 'nama', label: 'Nama' },
        { key: 'skor', label: 'Skor' }
      ],
      kategoriLeaderboard: ['DPO Catcher', 'Processing Suspect'] // Tambahkan kategori lain jika diperlukan
    };
  },
  methods: {
    filterLeaderboard(kategori) {
      return this.leaderboard.filter(item => item.kategori === kategori);
    }
  }
};
</script>

<style scoped>
/* Tambahkan gaya khusus jika diperlukan */
.mb-3 {
  margin-bottom: 1rem !important; /* Mengurangi margin-bottom */
}
</style>
