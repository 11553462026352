<template>
  <b-card>
    <template #header>
      <h4 class="card-title text-white">Performa Penangkapan Kendaraan per Bulan</h4>
    </template>
    <div id="chart1" v-if="valid">
      <apexchart type="bar" :options="chartOptions" :series="chartSeries"></apexchart>
    </div>
    <div id="chart1" v-else>
      Belum ada data
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'Dashboard',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      chartOptions: {},
      chartSeries: [],
      valid: true,
    };
  },
  methods: {
    init(){
      this.getDataKriminal();
    },
    getDataKriminal: function(){
      this.Loading = true;
      axios.post("idp/dashboard/chart-dpk",).then((response) => {
        this.valid = response.data.valid
        this.chartOptions= {
          chart: {
            height: 350,
            type: "bar",
            foreColor: "#fff", // Set foreColor to white
          },
          xaxis: {
            categories: response.data.categories,
            labels: {
              style: {
                colors: "#fff", // Set label text color to white
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#fff", // Set label text color to white
              },
            },
          },
        };
        this.chartSeries= [
          {
            name: "Tingkat Kriminal",
            data: response.data.series,
          },
        ];
      }).catch((error) => {
      }).finally(() => {
        this.Loading = false;
      });
    },
  },
  mounted(){
    this.init();
  }
}
</script>